import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { IntellectaVersion } from "../types";
import { useFirebaseContext } from "@/context/firebase-context";
import { chatStore } from "../store/chat-store";
import { INTELLECTA_ENDPOINT } from "@/config/index";
import { auth_token } from "./auth";

type Payload = {
  user: string;
  machine?: string;
  version: IntellectaVersion;
  //* dovrebbe anche accettare altre cose (e.g.: alarm_code)
  params?: { [key: string]: unknown };
};

const Response = z.object({
  value: z.string(),
});

type Response = z.infer<typeof Response>;

export const usePowerWord = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";
  const { machine } = chatStore();

  return useMutation<
    Response,
    Error,
    { payload: Omit<Payload, "user"> } & { endpoint: string }
  >({
    mutationFn: async ({ payload, endpoint }) => {
      const response = await fetch(INTELLECTA_ENDPOINT + endpoint, {
        headers: {
          Authorization: `Basic ${auth_token}`,
        },
        body: JSON.stringify({ ...payload, user: email, machine }),
        method: "POST",
      });

      if (!response.ok) {
        throw new Error("An error occurred");
      }

      const data = (await response.json()) as Response;

      return Response.parse(data);
    },
    onError: () => {
      toast.error("An error occurred");
    },
  });
};
