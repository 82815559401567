import { useState } from "react";
import {
  Box,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, Edit, MoreVert } from "@mui/icons-material";
import { Menu } from "@/components/ui/Menu";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useTranslate } from "@/i18n/config";

const ChatBox = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  transition: "background-color 0.3s",
  borderRadius: 4,
  cursor: "pointer",
  padding: ".25rem .5rem",
  "&:hover": {
    backgroundColor: "#ffffff15",
  },
});

export const HistoricChat = ({ title }: { title: string; id: string }) => {
  const translate = useTranslate();
  const { close: hide, open: show, isOpen: isHover } = useDisclosure();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);

    if (!isHover) {
      hide();
    }
  };

  return (
    <ChatBox onMouseEnter={show} onMouseLeave={hide}>
      <Stack alignItems="center" justifyContent="space-between" direction="row">
        <Tooltip title={title}>
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        </Tooltip>

        <IconButton
          sx={{
            visibility: isHover ? "visible" : "hidden",
          }}
          size="small"
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
      </Stack>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuList dense>
          <MenuItem
            sx={{
              fontWeight: "bold",
            }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            {translate("actions.rename")}
          </MenuItem>
          <MenuItem
            sx={{
              color: "#ff2200",
              fontWeight: "bold",
            }}
          >
            <ListItemIcon>
              <Delete
                sx={{
                  color: "#ff2200 !important",
                }}
              />
            </ListItemIcon>
            {translate("actions.delete")}
          </MenuItem>
        </MenuList>
      </Menu>
    </ChatBox>
  );
};
