import { Stack, Typography } from "@mui/material";
import { ChatIcon } from "./ChatIcon";
import { Sender } from "../../store/chat-store";

export const ChatMessageHeader = ({ sender }: { sender: Sender }) => {
  const name = sender === "User" ? "You" : sender;

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      alignContent="center"
      gap={1}
    >
      <ChatIcon sender={sender} />
      <Typography variant="body2">{name}</Typography>
    </Stack>
  );
};
