import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { ChatInput } from "./ChatInput";
import { ChatMessage } from "../ChatMessage/ChatMessage";
import { chatStore } from "../../store/chat-store";
import { useAskQuestion } from "../../api/useAskQuestion";
import { intellectaVersionStore } from "../../store/intellecta-version";
import { scrollChat } from "../../utilities/scrollChat";
import { parseChatHistory } from "../../utilities/parseChatHistory";
import { DefaultChatMessage } from "./DefaultChatMessage";
import { InfoDialogButton } from "../InfoDialog";
import { useTranslate } from "@/i18n/config";
import { HistoricChatButton } from "./HistoricChatButton";
import {
  SENTRY_MODE_MESSAGES,
  SENTRY_MODE_TIMER_BETWEEN_MESSAGES_S,
  sentryModeStore,
} from "../../store/sentry-mode-store";

const renderHistoric = false;
let index = 0;

export const ChatPanel = ({
  userInput,
  setUserInput,
  open,
}: {
  userInput: string;
  setUserInput: (inputText: string) => void;
  open: () => void;
}) => {
  const translate = useTranslate();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutateAsync: askQuestion, isLoading } = useAskQuestion();
  const { messages, addMessage, machine, isAnswering } = chatStore();
  const { isActive, resetTimer } = sentryModeStore();
  const intellectaVersion = intellectaVersionStore((state) => state.version);

  const id = searchParams.get("id");
  const chatMessages = id ? messages[id] || [] : [];

  const chatId = id || uuidv4();

  const submitHandler = () => {
    if (intellectaVersion === "troubleshooting" && !machine) {
      return toast.error(translate("intellecta.no_machine_selected"));
    }
    resetTimer();

    const id = searchParams.get("id");

    const chatId = id || uuidv4();

    if (!id) {
      setSearchParams({ id: chatId });
    }

    addMessage(chatId, {
      sender: "User",
      message: userInput,
      includeInHistoric: true,
    });

    scrollChat();

    askQuestion({
      query: userInput,
      version: intellectaVersion!,
      chat_history: parseChatHistory(chatMessages),
    })
      .then((response) => {
        addMessage(chatId, {
          sender: "Intellecta",
          message: response.value,
          includeInHistoric: true,
          chart: response.chart,
          image: response.image,
        });
      })
      .finally(() => {
        setUserInput("");
        resetTimer();
        scrollChat();
      });
  };

  useEffect(() => {
    let sentryModeInterval: NodeJS.Timeout | null;

    const printSentryModeMessage = () => {
      addMessage(chatId, {
        message: SENTRY_MODE_MESSAGES[index],
        sender: "Sentry Mode",
        includeInHistoric: false,
      });

      index++;
      scrollChat();
    };

    if (isActive) {
      printSentryModeMessage();
      sentryModeInterval = setInterval(
        printSentryModeMessage,
        SENTRY_MODE_TIMER_BETWEEN_MESSAGES_S * 1000,
      );
    }

    return () => {
      if (sentryModeInterval) clearTimeout(sentryModeInterval);
    };
  }, [isActive, addMessage]);

  return (
    <Stack
      flex={1}
      bgcolor={theme.palette.background.default}
      position="relative"
    >
      <Stack
        gap={2}
        flex={1}
        height="100%"
        width="100%"
        sx={{
          overflowY: "auto",
        }}
        id="chat-container"
        p={2}
      >
        {chatMessages.length === 0 && <DefaultChatMessage />}
        {chatMessages.map((message, i) => (
          <ChatMessage key={i} {...message} />
        ))}
        {(isLoading || isAnswering) && (
          <ChatMessage
            sender="Intellecta"
            message={""}
            isLoading
            disableFeedback
          />
        )}
      </Stack>
      <Stack p={2} pb={0.5} alignItems="center">
        <ChatInput
          disabled={isLoading}
          onChange={(e) => {
            setUserInput(e.target.value);
          }}
          onSubmit={submitHandler}
          value={userInput}
        />
        <Typography pt={0.5} variant="caption" sx={{ color: "#ddd" }}>
          {translate("intellecta.disclaimer_2")}
        </Typography>
      </Stack>

      <Box
        sx={{
          position: "absolute",
          right: 4,
          bottom: 4,
        }}
      >
        <InfoDialogButton />
      </Box>
      {renderHistoric && <HistoricChatButton open={open} />}
    </Stack>
  );
};
