import { Stack, Typography } from "@mui/material";
import { intellectaVersionStore } from "../../store/intellecta-version";
import { Prompt } from "../../types";
import { PromptButton } from "./PromptButton";
import { useTranslate } from "@/i18n/config";

export const PromptButtonList = ({
  prompts,
  onPromptClick,
}: {
  prompts: Prompt[];
  onPromptClick: (prompt: Prompt) => void;
}) => {
  const translate = useTranslate();
  const { version } = intellectaVersionStore();

  const filteredPrompts = prompts.filter(
    (prompt) => prompt.version === version,
  );

  return (
    <Stack gap={2}>
      {filteredPrompts.length > 0 ? (
        filteredPrompts.map((prompt) => (
          <PromptButton
            key={prompt.prompt}
            prompt={prompt}
            onPromptClick={onPromptClick}
          />
        ))
      ) : (
        <Typography>{translate("intellecta.no_prompts")}</Typography>
      )}
    </Stack>
  );
};
