import { Chat } from "../api/useAskQuestion";
import { Message } from "../store/chat-store";

export function parseChatHistory(messages: Message[]): Chat[] {
  const filteredMessages: Message[] = messages.filter(
    (message) => message.includeInHistoric,
  );

  const historicChat: Chat[] = [];

  for (let i = 0; i < filteredMessages.length; i += 2) {
    if (!filteredMessages[i + 1]) {
      break;
    }

    const [user, intellecta] =
      filteredMessages[i].sender === "Intellecta"
        ? [filteredMessages[i + 1].message, filteredMessages[i].message]
        : [filteredMessages[i].message, filteredMessages[i + 1].message];

    historicChat.push({
      user,
      intellecta,
    });
  }

  return historicChat;
}
