import { useSearchParams } from "react-router-dom";
import { ArrowRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useTranslate } from "@/i18n/config";
import { sentryModeStore } from "../../store/sentry-mode-store";

export const NewChatButton = () => {
  const translate = useTranslate();
  const [, setSearchParams] = useSearchParams();
  const { resetTimer } = sentryModeStore();

  return (
    <Button
      fullWidth
      endIcon={<ArrowRight />}
      variant="outlined"
      color="primary"
      onClick={() => {
        setSearchParams(uuidv4());
        resetTimer();
      }}
    >
      {translate("intellecta.new_chat")}
    </Button>
  );
};
