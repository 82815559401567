import { useState, useEffect, ChangeEvent } from "react";
import {
  Box,
  Button,
  Stack,
  Switch,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { parseSecondsLeft } from "../../utilities/parseSecondsLeft";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[300],
    "&:hover": {
      backgroundColor: alpha(pink[300], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[300],
  },
}));

export const SentryMode = () => {
  const [isActivating, setIsActivating] = useState(false);
  const { isActive, setIsActive, secondsLeft, resetTimer, clearTimer } =
    sentryModeStore();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActivating(event.target.checked);
    if (event.target.checked) {
      resetTimer();
    } else {
      setIsActive(false);
      clearTimer();
    }
  };

  const handleEnableNowClick = () => {
    if (isActivating) {
      clearTimer();
      setIsActive(true);
    }
  };

  useEffect(() => {
    if (secondsLeft === 0 && isActivating) {
      clearTimer();
      setIsActive(true);
    }
  }, [secondsLeft, isActivating, setIsActive, clearTimer]);

  return (
    <Stack p={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button
          onClick={handleEnableNowClick}
          sx={{ color: isActive ? pink[300] : undefined }}
        >
          Sentry Mode
        </Button>
        <PinkSwitch
          checked={isActivating}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>
      {!isActive && isActivating && secondsLeft > 0 && (
        <>
          <Typography variant="caption">
            Sentry Mode will activate in {parseSecondsLeft(secondsLeft)}.
          </Typography>
          <Typography
            variant="caption"
            sx={{ cursor: "pointer", color: pink[300] }}
            onClick={handleEnableNowClick}
          >
            Enable now
          </Typography>
        </>
      )}
    </Stack>
  );
};

// import { useState, useEffect, useRef, ChangeEvent } from "react";
// import {
//   Box,
//   Button,
//   Stack,
//   Switch,
//   Typography,
//   alpha,
//   styled,
// } from "@mui/material";
// import { pink } from "@mui/material/colors";
// import { sentryModeStore } from "../../store/sentry-mode-store";

// // const SENTRY_MODE_TIMER_DURATION_S = 10;

// //? disabilito con INVIO, NEW CHAT, POWER WORD

// const PinkSwitch = styled(Switch)(({ theme }) => ({
//   "& .MuiSwitch-switchBase.Mui-checked": {
//     color: pink[300],
//     "&:hover": {
//       backgroundColor: alpha(pink[300], theme.palette.action.hoverOpacity),
//     },
//   },
//   "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
//     backgroundColor: pink[300],
//   },
// }));

// export const SentryMode = () => {
//   const [isActivating, setIsActivating] = useState(false);
//   const { setIsActive, secondsLeft, setSecondsLeft, resetSecondsLeft } =
//     sentryModeStore();
//   // const { isActive, setIsActive } = sentryModeStore();
//   // const [secondsLeft, setSecondsLeft] = useState(SENTRY_MODE_TIMER_DURATION_S);
//   const timerRef = useRef<NodeJS.Timeout | null>(null);

//   const clearTimer = () => {
//     if (timerRef.current) {
//       clearInterval(timerRef.current);
//     }
//   };

//   const resetTimer = () => {
//     clearTimer();
//     // setSecondsLeft(SENTRY_MODE_TIMER_DURATION_S);
//     resetSecondsLeft();
//     timerRef.current = setInterval(() => {
//       console.log(secondsLeft);
//       // setSecondsLeft((prev) => prev - 1);
//       setSecondsLeft(secondsLeft - 1);
//     }, 1000);
//   };

//   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
//     setIsActivating(event.target.checked);
//     console.log(event.target.checked);
//     if (event.target.checked) {
//       resetTimer();
//     } else {
//       setIsActive(false);
//       clearTimer();
//     }
//   };

//   const handleEnableNowClick = () => {
//     // Reset the timer and perform the function immediately
//     setSecondsLeft(0);
//     clearTimer();
//   };

//   useEffect(() => {
//     return () => clearTimer();
//   }, []);

//   useEffect(() => {
//     if (secondsLeft === 0) {
//       clearTimer();
//       setIsActive(true);
//     }
//   }, [secondsLeft, setIsActive]);

//   return (
//     <Stack p={1}>
//       <Box display="flex" alignItems="center" justifyContent="space-between">
//         {/* <Typography color={pink[300]}>Sentry Mode</Typography> */}
//         <Button
//           onClick={handleEnableNowClick}
//           // sx={{ color: isActive ? pink[300] : undefined }}
//           sx={{
//             color: isActivating && secondsLeft === 0 ? pink[300] : undefined,
//           }}
//         >
//           Sentry Mode
//         </Button>
//         <PinkSwitch
//           // checked={isActive && secondsLeft === 0}
//           checked={isActivating}
//           onChange={handleChange}
//           inputProps={{ "aria-label": "controlled" }}
//         />
//       </Box>
//       {isActivating && secondsLeft > 0 && (
//         <>
//           <Typography variant="caption">
//             Sentry Mode will activate in {secondsLeft} seconds.
//           </Typography>
//           <Typography
//             variant="caption"
//             sx={{ cursor: "pointer", color: pink[300] }}
//             onClick={handleEnableNowClick}
//           >
//             Enable now
//           </Typography>
//         </>
//       )}
//     </Stack>
//   );
// };
