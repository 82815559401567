import { ContentPaste, Poll } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useDisclosure } from "@/hooks/useDisclosure";
import { ChatMessageAction } from "./ChatMessageAction";
import { FeedbackModal } from "./FeedbackModal";
import { useTranslate } from "@/i18n/config";

export const ChatMessageActionList = ({ onCopy }: { onCopy: () => void }) => {
  const translate = useTranslate();
  const { open, isOpen, close } = useDisclosure();

  return (
    <>
      <Stack direction="row" gap={1}>
        <ChatMessageAction
          onClick={onCopy}
          popoverText={translate("actions.copy")}
          icon={<ContentPaste />}
        ></ChatMessageAction>
        <ChatMessageAction
          onClick={open}
          popoverText={translate("intellecta.leave_feedback")}
          icon={<Poll />}
        ></ChatMessageAction>
      </Stack>
      <FeedbackModal isOpen={isOpen} close={close} />
    </>
  );
};
