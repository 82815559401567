import { BasicTable } from "@/components/BasicTable";
import { Card } from "@/components/Layout/Card";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import { Typography } from "@mui/material";
import { getReportData } from "./utils/display-report";
import { getDataForDownload } from "./utils/download-report";
import { useGetReportsList } from "./api/useGetReportsList";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { OverviewNavBar } from "@/pages/Overview/layout/OverviewNavBar/OverviewNavBar";
import { useTranslate } from "@/i18n/config";

export const Reports = () => {
  const translate = useTranslate();
  const { data, isLoading } = useGetReportsList();

  const columnsDef: GridColDef[] = [
    {
      field: "year",
      headerName: translate("durations.year"),
      flex: 1,
    },
    {
      field: "type",
      headerName: translate("reports.periodicity"),
      flex: 1,
    },
    {
      field: "date",
      headerName: translate("reports.period"),
      flex: 1,
    },
    {
      field: "timestamp",
      headerName: translate("timestamp"),
      flex: 1,
    },
    {
      field: "machine",
      headerName: translate("machine"),
      flex: 1,
    },
    {
      field: "filePath",
      headerName: translate("reports.report"),
      renderCell: (params: GridRenderCellParams) => (
        <ReportContent {...params} />
      ),
      flex: 1,
    },
    {
      field: "downloadFile",
      headerName: translate("actions.download"),
      renderCell: (params: GridRenderCellParams) => (
        <DownloadContent {...params} />
      ),
      flex: 1,
    },
  ];
  return (
    <>
      <OverviewNavBar />
      {isLoading ? <SkeletonCard height={400} /> : null}
      {data ? (
        <Card>
          <Typography variant="h5">
            {translate("reports.machines_reports")}
          </Typography>
          <BasicTable
            columns={columnsDef}
            rows={data.filesList}
            fileName={translate("reports.machines_reports")}
            classes={{ height: 600 }}
          />
        </Card>
      ) : null}
    </>
  );
};

const ReportContent = (params: GridRenderCellParams) => {
  return (
    <>
      <GridActionsCellItem
        icon={<StickyNote2OutlinedIcon sx={{ cursor: "pointer" }} />}
        label="report"
        onClick={() => {
          getReportData(params.value);
        }}
      />
    </>
  );
};

const DownloadContent = (params: GridRenderCellParams) => {
  return (
    <>
      <GridActionsCellItem
        icon={<FileDownloadOutlinedIcon sx={{ cursor: "pointer" }} />}
        label="report"
        onClick={() => {
          getDataForDownload(params.value);
        }}
      />
    </>
  );
};
