import { ChevronRight } from "@mui/icons-material";
import {
  Box,
  Drawer,
  Stack,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { ChatHistory } from "./ChatHistory";
import { InfoDialogButton } from "../InfoDialog";

export const HistoricChatPanel = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const theme = useTheme();

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      onClose={close}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: "none",
        },
      }}
    >
      <Stack
        sx={{
          position: "relative",
        }}
        height="100%"
        width={268}
        p={2}
      >
        <IconButton
          sx={{
            alignSelf: "flex-end",
          }}
          onClick={close}
        >
          <ChevronRight />
        </IconButton>

        <Typography mb={2} variant="h6">
          History
        </Typography>
        <ChatHistory />

        <Box alignSelf="flex-end">
          <InfoDialogButton />
        </Box>
      </Stack>
    </Drawer>
  );
};
