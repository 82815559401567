import { toast } from "react-hot-toast";
import { ArrowUpward } from "@mui/icons-material";
import { TextField, IconButton } from "@mui/material";
import { intellectaVersionStore } from "../../store/intellecta-version";
import { chatStore } from "../../store/chat-store";
import { useTranslate } from "@/i18n/config";

export const ChatInput = ({
  onSubmit,
  value,
  onChange,
  disabled = false,
}: {
  onSubmit: () => void;
  value: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
  const translate = useTranslate();
  const { version } = intellectaVersionStore();
  const { machine } = chatStore();

  return (
    <TextField
      placeholder={translate("intellecta.type_a_message")}
      variant="outlined"
      disabled={disabled}
      value={value}
      multiline
      onKeyDown={(e) => {
        const { key, shiftKey } = e.nativeEvent;

        if (key === "Enter" && !shiftKey) {
          e.preventDefault();
          if (value === "") return;
          if (version === "troubleshooting" && !machine) {
            toast.error(translate("intellecta.no_machine_selected"));
          } else {
            onSubmit();
          }
        }
      }}
      onChange={onChange}
      size="small"
      InputProps={{
        sx: { backgroundColor: "#192133" },
        endAdornment: (
          <IconButton
            disabled={disabled || value === ""}
            onClick={onSubmit}
            type="submit"
            size="small"
            sx={{ alignSelf: "flex-end" }}
          >
            <ArrowUpward />
          </IconButton>
        ),
      }}
      sx={{ width: "80%" }}
    />
  );
};
