import { useEffect } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useGetUserConfiguration } from "../../api/useGetUserConfiguration";
import { chatStore } from "../../store/chat-store";
import { useTranslate } from "@/i18n/config";

export const TroubleShootingMachineSelector = () => {
  const translate = useTranslate();
  const { data, isLoading } = useGetUserConfiguration();
  const { setMachine } = chatStore();

  useEffect(() => {
    return () => setMachine(null);
  }, [setMachine]);

  if (isLoading || !data) {
    return <div>{translate("user_feedback.loading")}</div>;
  }

  const machines = data?.machines.filter((machine) =>
    machine.service_version_access.includes("troubleshooting"),
  );

  if (!machines.length) {
    return (
      <Box>
        <Typography variant="body2">
          {translate("intellecta.no_machines_access")}
        </Typography>
      </Box>
    );
  }

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">
        {translate("select_machine")}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        onChange={(e) => setMachine(e.target.value)}
      >
        {machines.map((machine) => (
          <FormControlLabel
            key={machine.machine}
            value={machine.machine}
            control={<Radio />}
            label={machine.machine}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
