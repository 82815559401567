import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Stack } from "@mui/material";
import { ChatMessageActionList } from "./ChatMessageActionList";
import { Message } from "../../store/chat-store";
import { ChatMessageHeader } from "./ChatMessageHeader";
import { ChatMessageLoader } from "./ChatMessageLoader";
import { ChatMessageChart } from "./ChatMessageChart";
import { ChatMessageBody } from "./ChatMessageBody";
import "./chat-markdown.css";
import { useTranslate } from "@/i18n/config";

export const ChatMessage = ({
  message,
  sender,
  chart,
  image,
  disableFeedback = false,
  isLoading = false,
}: Omit<Message, "includeInHistoric"> & {
  disableFeedback?: boolean;
  isLoading?: boolean;
}) => {
  const [isLoadingSentry, setIsLoadingSentry] = useState(true);
  const translate = useTranslate();
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(message);
    toast.success(translate("user_feedback.copied_to_clipboard"));
  };

  useEffect(() => {
    const rnd = Math.round(Math.random() * 30 * 1000);
    const timer = setTimeout(() => {
      setIsLoadingSentry(false);
    }, rnd);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Stack gap={1} borderBottom="1px solid #ffffff15" p={2}>
      <ChatMessageHeader sender={sender} />

      {isLoading && <ChatMessageLoader />}
      {sender === "Sentry Mode" && isLoadingSentry && <ChatMessageLoader />}

      {sender === "Sentry Mode" && !isLoadingSentry && (
        <ChatMessageBody message={message} />
      )}
      {sender !== "Sentry Mode" && <ChatMessageBody message={message} />}
      {/* <ChatMessageBody message={message} /> */}

      {image && (
        <img
          src={`data:image/png;base64,${image}`}
          alt="Decoded Image"
          style={{ maxWidth: "fit-content", height: "auto" }}
        />
      )}

      {chart && <ChatMessageChart chart={chart} />}

      {sender === "Intellecta" && !disableFeedback && (
        <ChatMessageActionList onCopy={handleCopyToClipboard} />
      )}
    </Stack>
  );
};
