import { Box, List, ListItem, Typography } from "@mui/material";
import { useGetUserConfiguration } from "../../api/useGetUserConfiguration";
import { useTranslate } from "@/i18n/config";

export const DataAnalysisMachineList = () => {
  const translate = useTranslate();
  const { data, isLoading } = useGetUserConfiguration();

  if (isLoading || !data) {
    return <div>{translate("user_feedback.loading")}</div>;
  }

  const machines = data?.machines.filter((machine) =>
    machine.service_version_access.includes("data-analysis"),
  );

  if (!machines.length) {
    return (
      <Box>
        <Typography variant="body2">
          {translate("intellecta.no_machines_access")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="body2">
        {translate("intellecta.data_analysis_machines")}
      </Typography>

      <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
        {machines.map((machine) => (
          <ListItem sx={{ display: "list-item" }} key={machine.machine}>
            {machine.machine}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
