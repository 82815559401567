import { z } from "zod";

export const intellectaVersion = z.union([
  z.literal("troubleshooting"),
  z.literal("data-analysis"),
  z.literal("line-audit"),
]);

export type IntellectaVersion = z.infer<typeof intellectaVersion>;

export const service = z.union([
  z.literal("sentry"),
  z.literal("altro_servizio"),
]);

export type Service = z.infer<typeof service>;

export const prompt = z.object({
  label: z.string(),
  prompt: z.string(),
  description: z.string(),
  version: intellectaVersion,
});

export type Prompt = z.infer<typeof prompt>;

export const powerWord = z.object({
  label: z.string(),
  endpoint: z.string(),
  prompt: z.string(),
  description: z.string(),
  version: z.array(intellectaVersion),
  type: z.enum(["standard", "specific-alarm"]),
});

export type PowerWord = z.infer<typeof powerWord>;

export const config = z.object({
  powerwords: z.array(powerWord),
  prompts: z.array(prompt),
  machines: z.array(
    z.object({
      machine: z.string(),
      service_version_access: z.array(intellectaVersion),
    }),
  ),
  versions: z.array(intellectaVersion),
  services: z.array(service),
});

export type Config = z.infer<typeof config>;

export const chart = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("linechart"),
    values: z.array(z.tuple([z.number(), z.number()])),
  }),
  z.object({
    type: z.literal("piechart"),
    categories: z.array(z.string()),
    values: z.array(z.number()),
  }),
  z.object({
    type: z.literal("barchart"),
    categories: z.array(z.string()),
    values: z.array(z.number()),
  }),
  z.object({
    type: z.literal("scatterplot"),
    values: z.array(z.tuple([z.number(), z.number()])),
  }),
]);

export type Chart = z.infer<typeof chart>;
