import { Button, Tooltip } from "@mui/material";
import { Prompt } from "../../types";

export const PromptButton = ({
  prompt,
  onPromptClick,
}: {
  prompt: Prompt;
  onPromptClick: (prompt: Prompt) => void;
}) => {
  return (
    <Tooltip title={prompt.description}>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => onPromptClick(prompt)}
      >
        {prompt.label}
      </Button>
    </Tooltip>
  );
};
