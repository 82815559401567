import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { chatStore } from "../store/chat-store";
import { IntellectaVersion, chart } from "../types";
import { INTELLECTA_ENDPOINT } from "@/config/index";
import { auth_token } from "./auth";

type Payload = {
  user: string;
  query: string;
  version: IntellectaVersion;
  chat_history: Chat[];
  machine?: string;
};
export type Chat = {
  user: string;
  intellecta: string;
};

const Response = z.object({
  value: z.string(),
  chart: chart.optional(),
  // opzionale string base64 binario dell'immagine
  // images: z.optional(z.array(z.string())),
  image: z.optional(z.string()),
});

type Response = z.infer<typeof Response>;

export const useAskQuestion = () => {
  const { machine } = chatStore();
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation<Response, Error, Omit<Payload, "user" | "machine">>({
    mutationFn: async (payload) => {
      const response = await fetch(INTELLECTA_ENDPOINT + "/ask", {
        headers: {
          Authorization: `Basic ${auth_token}`,
        },
        body: JSON.stringify({ ...payload, user: email, machine }),
        method: "POST",
      });

      if (!response.ok) {
        throw new Error("An error occurred");
      }

      const data = (await response.json()) as Response;

      return Response.parse(data);
    },
    onError: () => {
      toast.error("An error occurred");
    },
  });
};
