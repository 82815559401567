import { ReactNode, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";

export const ChatMessageAction = ({
  icon,
  popoverText,
  ...rest
}: {
  icon: ReactNode;
  popoverText: string;
} & React.ComponentProps<typeof IconButton>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Tooltip arrow title={popoverText}>
        <IconButton
          {...rest}
          aria-owns={open ? "mouse-over-popover" : undefined}
          size="small"
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </div>
  );
};
