import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useFirebaseContext } from "@/context/firebase-context";
import { config, Config } from "../types";
import { INTELLECTA_ENDPOINT } from "@/config/index";
import { auth_token } from "./auth";

export const useGetUserConfiguration = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useQuery<Config, Error>({
    queryKey: ["intellecta-config", email],
    queryFn: async () => {
      const response = await fetch(INTELLECTA_ENDPOINT + "/user/config", {
        headers: {
          Authorization: `Basic ${auth_token}`,
        },
        body: JSON.stringify({ user: email }),
        method: "POST",
      });

      if (!response.ok) {
        throw new Error("An error occurred");
      }

      const data = (await response.json()) as Config;

      return config.parse(data);
    },
    staleTime: 1000 * 60 * 60,
    onError: () => {
      toast.error("An error occurred");
    },
  });
};
