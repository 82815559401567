import { create } from "zustand";
import { Chart } from "../types";

export type Sender = "Intellecta" | "User" | "Sentry Mode";

export type Message = {
  // isIntellecta: boolean;
  sender: Sender;
  message: string;
  includeInHistoric: boolean;
  chart?: Chart; // non riescono a farlo quindi forse sparirà
  image?: string;
};

export const chatStore = create<{
  messages: Record<string, Message[]>; // chiave UID, valore array di oggetti - salvo domanda utente - risposta - domanda - risposta - etc...
  // siccome non c'è un db -> non è salvata la conversazione e non c'è il contesto
  // a loro serve un array di oggetti dove ogni oggetto ha sia botta che risposta
  addMessage: (uuid: string, message: Message) => void;
  machine: string | null;
  setMachine: (machine: string | null) => void;
  isAnswering: boolean;
  setIsAnswering: (isAnswering: boolean) => void;
}>((set) => ({
  messages: {},

  addMessage: (uuid, message) =>
    set((state) => {
      const newState = { ...state };

      if (!newState.messages[uuid]) {
        newState.messages[uuid] = [];
      }

      newState.messages[uuid].push(message);

      return newState;
    }),

  machine: null,

  setMachine: (machine) => {
    set((state) => {
      const newState = { ...state };

      newState.machine = machine;

      return newState;
    });
  },

  isAnswering: false,

  setIsAnswering: (isAnswering) => {
    set((state) => {
      const newState = { ...state };

      newState.isAnswering = isAnswering;

      return newState;
    });
  },
}));
