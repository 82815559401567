import { memo } from "react";
import { Stack, Typography } from "@mui/material";
import { faker } from "@faker-js/faker";
import { v4 as uuidv4 } from "uuid";
import { HistoricChat } from "./HistoricChat";

type HistoricChat = {
  title: string;
  id: string;
  timestamp: number;
};

const historicFakeChats: HistoricChat[] = [...Array(50)].map(() => ({
  title: faker.random.words(4),
  id: uuidv4(),
  timestamp: faker.date.recent(30).getTime(),
}));

const groupedChartsByDate: Record<string, HistoricChat[]> =
  historicFakeChats.reduce(
    (acc, chat) => {
      const date = new Date(chat.timestamp).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(chat);
      return acc;
    },
    {} as Record<string, HistoricChat[]>,
  );

export const ChatHistory = memo(() => {
  return (
    <>
      <Stack
        flex={1}
        sx={{
          overflowY: "auto",
          height: "100%",
        }}
        gap={2}
      >
        {Object.entries(groupedChartsByDate).map(([date, chats], index) => (
          <Stack
            key={index}
            gap={1}
            borderBottom={`1px solid #ffffff15`}
            py={2}
          >
            <Typography variant="body2" fontWeight="bold" fontSize={".75rem"}>
              {date}
            </Typography>
            {chats.map((chat) => (
              <HistoricChat title={chat.title} id={chat.id} key={chat.id} />
            ))}
          </Stack>
        ))}
      </Stack>
    </>
  );
});
