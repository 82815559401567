import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Button, Tooltip } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { PowerWord } from "../../types";
import { chatStore } from "../../store/chat-store";
import { usePowerWord } from "../../api/usePowerWord";
import { intellectaVersionStore } from "../../store/intellecta-version";
import { scrollChat } from "../../utilities/scrollChat";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { useTranslate } from "@/i18n/config";

export const PowerwordButtonStandard = ({
  powerWord,
}: {
  powerWord: PowerWord;
}) => {
  const translate = useTranslate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { addMessage } = chatStore();
  const { version } = intellectaVersionStore();
  const { mutateAsync: callPowerWord } = usePowerWord();
  const { machine, setIsAnswering } = chatStore();
  const { resetTimer } = sentryModeStore();

  const onClick = async () => {
    if (!version)
      return toast.error(translate("user_feedback.an_error_occurred"));

    //? in versione 'troubleshooting' devo prima selezionare una macchina, fatta eccezione per 'help'
    if (
      version === "troubleshooting" &&
      !machine &&
      powerWord.endpoint !== "/powerword/help"
    ) {
      return toast.error("No machine selected");
    }

    setIsAnswering(true);
    resetTimer();

    const id = searchParams.get("id");

    const chatId = id || uuidv4();

    if (!id) {
      setSearchParams({ id: chatId });
    }

    addMessage(chatId, {
      sender: "User",
      message: powerWord.prompt,
      includeInHistoric: true,
    });

    scrollChat();

    callPowerWord({
      endpoint: powerWord.endpoint,
      payload: { version },
    })
      .then((response) => {
        addMessage(chatId, {
          sender: "Intellecta",
          message: response.value,
          includeInHistoric: true,
        });
      })
      .finally(() => {
        scrollChat();
        setIsAnswering(false);
        resetTimer();
      });
  };

  return (
    <Tooltip title={powerWord.description}>
      <Button fullWidth variant="outlined" onClick={onClick}>
        {powerWord.label}
      </Button>
    </Tooltip>
  );
};
