import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Card } from "@/components/Layout/Card";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { PieChart } from "@/components/highcharts/piechart/Piechart";
import { ScatterPlot } from "@/components/highcharts/scatter/Scatter";
import { Chart } from "../../types";

export const ChatMessageChart = ({ chart }: { chart: Chart }) => {
  switch (chart.type) {
    case "linechart":
      return (
        <Card>
          <LineChart.Custom
            title=""
            yAxis={[
              {
                uom: "",
                series: [
                  {
                    name: "",
                    type: "line",
                    data: chart.values,
                  },
                ],
              },
            ]}
          />
        </Card>
      );
    case "barchart":
      return (
        <Card>
          <BarChart.Custom
            title=""
            uom=""
            categories={chart.categories}
            series={[
              {
                name: "",
                type: "column",
                data: chart.values,
              },
            ]}
          />
        </Card>
      );
    case "piechart":
      return (
        <Card>
          <PieChart.Custom
            title=""
            seriesOptions={{
              type: "pie",
              data: chart.values.map((value, index) => ({
                name: chart.categories[index],
                y: value,
              })),
            }}
          />
        </Card>
      );

    case "scatterplot":
      return (
        <Card>
          <ScatterPlot.Custom
            title=""
            yAxis={[
              {
                uom: "",
                series: [
                  {
                    name: "",
                    type: "scatter",
                    data: chart.values.map(([x, y]) => ({ x, y })),
                  },
                ],
              },
            ]}
          />
        </Card>
      );

    default:
      return null;
  }
};
