import { useState } from "react";
import { Stack } from "@mui/material";
import { Loader } from "@/components/Layout/Loader";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useGetUserConfiguration } from "./api/useGetUserConfiguration";
import { SidePanel } from "./components/SidePanel/SidePanel";
import { ChatPanel } from "./components/ChatPanel/ChatPanel";
import { HistoricChatPanel } from "./components/HistoricChatPanel/HistoricChatPanel";
import { InfoDialog } from "./components/InfoDialog";
import { NoVersionsMessage } from "./components/ChatPanel/NoVersionsMessage";

export const Intellecta = () => {
  const [userInput, setUserInput] = useState("");
  const [isShownAlert, setIsShownAlert] = useState(true);
  const { isLoading, data } = useGetUserConfiguration();
  const { open, close, isOpen } = useDisclosure();

  if (isLoading || !data) {
    return <Loader isLoading />;
  }

  return (
    <>
      <InfoDialog isOpen={isShownAlert} close={() => setIsShownAlert(false)} />
      <Stack
        flexDirection="row"
        sx={{
          height: "100vh",
        }}
      >
        {data.versions.length > 0 ? (
          <>
            <SidePanel
              config={data}
              onPromptClick={(prompt) => setUserInput(prompt)}
            />

            <ChatPanel
              userInput={userInput}
              setUserInput={setUserInput}
              open={open}
            />

            <HistoricChatPanel isOpen={isOpen} close={close} />
          </>
        ) : (
          <NoVersionsMessage />
        )}
      </Stack>
    </>
  );
};
