import { useFirebaseContext } from "@/context/firebase-context";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type ReportsListResponse = ThingworxError | ReportsListResult;
const Report = z.object({
  machine: z.string(),
  filePath: z.string(),
  type: z.union([z.literal("weekly"), z.literal("yearly"),z.literal("daily" )]),
  name: z.string(),
  date: z.string(),
  year: z.string(),
  timestamp: z.union([z.number(),z.string()]),
});
type Report = z.infer<typeof Report>;

const ReportsListResult = z.object({
  filesList: z.array(Report),
  response: z.literal(true),
});
export type ReportsListResult = z.infer<typeof ReportsListResult>;

export const useGetReportsList = () => {
  const { user } = useFirebaseContext();
  const payload = {
    user: user!.email,
    fileSystemName: "ima-tea-herbs-reports",
  };
  return useQuery<ReportsListResult>({
    queryKey: ["reports-list"],
    queryFn: async () => {
      const res = await fetch("https://ms.iotdataroom.com/report-manager/report-list", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const response = (await res.json()) as ReportsListResponse;
      if (!response.response) {
        throw new Error(response.errorString);
      }
      const validatedResponse = ReportsListResult.parse(response);
      return {
        ...validatedResponse,
        filesList: validatedResponse.filesList.map((file, index) => ({
          ...file,
          id: index,
          date: transformDate(file.date),
          downloadFile: file.filePath,
          timestamp: TimeHelpers.parseTimestampToString({timestamp: file.timestamp as number})
        })),
      };
    },
  });
};


function transformDate(value: string) {
  const dateString = value.startsWith("d")
    ? `day-${value.slice(1)}`
    : value.startsWith("w")
    ? `week-${value.slice(1)}`
    : value.startsWith("y")
    ? `year-${value.slice(1)}`
    : value;
  return dateString;
}
